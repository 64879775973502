<template>
    <div class="heating-second__wrapper"  >
            <div class="heating-second__prev" @click="prevSlide">
                <img src="@/assets/icons/swiperLeft.svg">
            </div>
        <Swiper 
        ref="heaterSwiper"
        :slides-per-view="1"
        >
            <swiper-slide  v-for="(item, index) in images" :key="index">
                <img style="width: 100%" :src="require('@/assets/img/' + item)"> 
            </swiper-slide>
        </Swiper>
            <div class="heating-second__next" @click="nextSlide">
                <img src="@/assets/icons/swiperRight.svg">
            </div>
        <h2 class="heating-second__wrapper-title">{{title}}</h2>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    data(){
        return{
        }
    },
    props:{
        images: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    // computed: {
    //     swiper() {
    //         return this.$refs.heaterSwiper;
    //     },
    // },
    methods: {
        prevSlide() {
        this.$refs.heaterSwiper.swiperInstance.slidePrev();
        },
        nextSlide() {
        this.$refs.heaterSwiper.swiperInstance.slideNext();
        },
    },
})  
</script>

<style lang="scss"  scoped>
    .heating-second{
            &__wrapper{
            max-width: 350px;
            // width: 400px;
            // width: 100%;
            position: relative;
            &-title{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
                color: #2A2C2C;
            }
        }
        &__prev, &__next{
            position: absolute;
            z-index: 99;
            top: 50%;
            transform: translateY(-50%);
        }
        &__next{
            right: 0;
        }
    }
</style>