<template>
  <div>
    <div class="heating__main">
      <div class="container">
        <div class="heating__main-left">
          <h4 class="heating__main-left-header">
            Темекі қыздыру құрылғысын сатып алып, Magnum Club картасына 3 500
            бонус ал
          </h4>

          <div class="heating__main-left-card">
            <div class="heating__main-left-heater-mobile">
              <img src="@/assets/img/heating/heater.png" />
            </div>
            <img src="@/assets/img/heating/bigcard.svg" />
          </div>
          <div class="heating__main-left-footer">
            *Бұл өнім сіздің денсаулығыңызға зиянды және тәуелділікті тудырады.
            Бұл веб-сайтта көрсетілген ақпарат пен суреттер "Magnum Cash&Carry"
            ЖШС никотині бар бұйымдардың тіркелген тұтынушыларына ғана арналған
          </div>
        </div>
        <div class="heating__main-right">
          <img src="@/assets/img/heating/heater.png" />
        </div>
      </div>
    </div>
    <div class="heating__explosion">
      <div class="container">
        <h2 class="heating__explosion-text">
          glo™ темекіні қыздыру технологиясы
        </h2>
        <img
          class="heating__explosion-img"
          src="@/assets/img/heating/disassembled.png"
        />
      </div>
    </div>
    <div class="heating__statistics">
      <div class="container">
        <div class="heating__statistics-texts">
          <h2 class="heating__statistics-texts-title">Қыздыру, жандыру емес</h2>
          <p class="heating__statistics-texts-text">
            Құрылғының ішінде темекі стигі салынатын цилиндр пішінді камера бар.
            Құрылғы қосылған кезде стик 200-260 градусқа дейін қыздырылады.
            Нәтижесінде никотинге қаныққан бу пайда болады. Қыздыру кезінде жану
            мүлдем болмайды, яғни токсиндер мен шайырлар аз бөлінеді.
          </p>
        </div>
        <div class="heating__statistics-stats">
          <div class="heating__statistics-stats-item">
            <div class="heating__statistics-stats-item-image">
              <img src="@/assets/img/heating/greenStat.png" />
            </div>
            <p>glo™ темекіні қыздыру технологиясы glo™ hyper+</p>
          </div>
          <div class="heating__statistics-stats-item">
            <div class="heating__statistics-stats-item-image">
              <img src="@/assets/img/heating/redStat.png" />
            </div>
            <p>Шылымның жану температурасы</p>
          </div>
        </div>
      </div>
    </div>
    <div class="heating__advantages">
      <div class="container">
        <div class="heating__advantages-top">
          <div class="heating__advantages-top__item">
            <img
              class="heating__advantages-top__item-image"
              src="@/assets/img/heating/check.png"
            />
            <h2 class="heating__advantages-top__item-title">
              Токсиндер 90-95% азырақ*
            </h2>
          </div>
          <div class="heating__advantages-top__item">
            <img
              class="heating__advantages-top__item-image"
              src="@/assets/img/heating/check.png"
            />
            <h2 class="heating__advantages-top__item-title">Иіс азырақ**</h2>
          </div>
          <div class="heating__advantages-top__item">
            <img
              class="heating__advantages-top__item-image"
              src="@/assets/img/heating/check.png"
            />
            <h2 class="heating__advantages-top__item-title">
              Түтін жоқ, күл жоқ**
            </h2>
          </div>
        </div>
        <div class="heating__advantages-bottom">
          <p>
            *Данный продукт не является безопасным и содержит никотин, вещество,
            вызывающее привыкание. Сравнение дыма научной стандартной эталонной
            сигареты (приблизительно 9 мг смолы) и выбросов от gloTM по среднему
            значению 9 вредных компонентов, которые Всемирная организация
            здравоохранения рекомендует снизить в составе сигаретного дыма.
            Примечание: данное утверждение применимо к gloTM pro, gloTM hyper и
            gloTM hyper +. <br /><br />**По сравнению с обычной сигаретой при
            курении. <br /><br />Вышеуказанные качества не обязательно означают,
            что этот продукт менее вреден, чем другие табачные изделия
          </p>
        </div>
      </div>
    </div>
    <div class="heating__format">
      <div class="container">
        <h2 class="heating__format-title">Өз форматыңды таңда</h2>
        <div class="heating__format-texts">
          <div class="heating__format-texts__item">
            <h2 class="heating__format-texts__item-title">glo™ hyper+</h2>
            <p class="heating__format-texts__item-text">
              Темекіге көбірек қанықтыру Жуан форматты стиктер үшін (деми) 12
              дәмнен тұратын кең ауқымы стик түрлері
            </p>
          </div>
          <div class="heating__format-texts__item">
            <h2 class="heating__format-texts__item-title">glo™ pro</h2>
            <p class="heating__format-texts__item-text">
              Темекіге орташа қанықтыру Жіңішке форматтағы стиктер үшін (нано) 7
              дәмнен тұратын кең ауқымы стик түрлері
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="heating__big">
      <div class="container">
        <div class="heating__big__top">
          <h2 class="heating__big__top-title">glo™ құрылғылары</h2>
          <div class="heating__big__top-sliders">
            <heating-slider style="" :images="first" :title="firstTitle" />
            <heating-slider style="" :images="first" :title="firstTitle" />
            <heating-slider style="" :images="first" :title="firstTitle" />
            <heating-slider style="" :images="first" :title="firstTitle" />
          </div>
        </div>
        <div class="heating__big__bottom">
          <h2 class="heating__big__bottom-title">glo™ арналған СТИКТЕР</h2>
          <div class="heating__big__bottom-sliders">
            <div class="heating__big__bottom-sliders__slider">
              <div class="heating__big__bottom-sliders__slider-title">
                <img src="@/assets/img/heating/demi.png" />
                <h2>Деми формат</h2>
              </div>
              <heating-second-slider :images="second" :title="secondTitle" />
            </div>
            <div class="heating__big__bottom-sliders__slider">
              <div class="heating__big__bottom-sliders__slider-title">
                <img src="@/assets/img/heating/nano.png" />
                <h2>nano формат</h2>
              </div>
              <heating-second-slider :images="second" :title="secondTitle" />
            </div>
          </div>
          <div class="heating__big__bottom-mobile-sliders">
            <div class="heating__big__bottom-mobile-titles">
              <div
                class="heating__big__bottom-mobile-title"
                :class="{ titleActive: activeMobile === 1 }"
                @click="activeMobile = 1"
              >
                <img src="@/assets/img/heating/demi.png" />
                <h2>Деми формат</h2>
              </div>
              <svg
                width="2"
                height="76"
                viewBox="0 0 2 76"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0L1 76" stroke="#2A2C2C" />
              </svg>
              <div
                class="heating__big__bottom-mobile-title"
                :class="{ titleActive: activeMobile === 2 }"
                @click="activeMobile = 2"
              >
                <img src="@/assets/img/heating/nano.png" />
                <h2>nano формат</h2>
              </div>
            </div>
            <div class="heating__big__bottom-mobile-slider">
              <heating-second-slider
                :images="second"
                :title="secondTitle"
                v-if="activeMobile === 1"
              />
              <heating-second-slider
                :images="second"
                :title="firstTitle"
                v-else-if="activeMobile === 2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import HeatingSecondSlider from "../../components/sliders/HeatingSecondSlider.vue";
import HeatingSlider from "../../components/sliders/HeatingSlider.vue";
export default Vue.extend({
  components: { HeatingSlider, HeatingSecondSlider },
  name: "Heating",
  data() {
    return {
      first: ["heating/blueglo.png", "heating/bigblackglo.png"],
      firstTitle: "HYPER+ UNIQ",
      second: ["heating/shtotest.svg", "heating/creamytobac.svg"],
      secondTitle: "neo™ Demi Red Boost капсулалы",
      activeMobile: 2,
    };
  },
});
</script>
<style lang="scss" scoped></style>
