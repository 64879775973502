<template>
  <div class="heating__wrapper">
    <div class="heating__prev" @click="prevSlide">
      <img src="@/assets/icons/swiperLeft.svg" />
    </div>
    <Swiper ref="heaterSwiper" :slides-per-view="1">
      <swiper-slide v-for="(item, index) in images" :key="index">
        <img :src="require('@/assets/img/' + item)" />
      </swiper-slide>
    </Swiper>
    <div class="heating__next" @click="nextSlide">
      <img src="@/assets/icons/swiperRight.svg" />
    </div>
    <h2 class="heating__wrapper-title">{{ title }}</h2>
  </div>
</template>

<script lang="ts" scoped>
import Vue from "vue";
export default Vue.extend({
  data() {
    return {};
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  // computed: {
  //     swiper() {
  //         return this.$refs.heaterSwiper;
  //     },
  // },
  methods: {
    prevSlide() {
      this.$refs.heaterSwiper.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.$refs.heaterSwiper.swiperInstance.slideNext();
    },
  },
});
</script>

<style lang="scss">
.heating__wrapper {
  max-width: 210px;
  width: 100%;
  position: relative;
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #2a2c2c;
  }
}
.heating__prev,
.heating__next {
  position: absolute;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
}
.heating__next {
  right: 0;
}
@media (max-width: 800px) {
  .heating {
    &__wrapper {
      margin: 10px 30px;
    }
  }
}
@media (max-width: 600px) {
  .heating {
    &__wrapper {
      max-width: 300px;
      margin: 0;
      img {
        width: 100%;
      }
    }
  }
}
</style>
